<template>
  <c-tab
    type="vertical"
    :tabItems="tabItems"
    :height="tabHeight"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :popupParam.sync="popupParam"
        :cto.sync="cto"
        :isOld="isOld"
        :disabled="disabled"
        @getDetail="getDetail"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'ctoTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    cto: {
      type: Object,
      default: () => ({
        sopCtoId: '',
        plantCd: '',
        ctoDate: '',
        ctoTime: '',
        observeUserId: '',
        observeCount: '',
        mdmSopId: '',
        workArea: '',
        ctoCompleteFlag: 'N',
        improveContents: '',
        priorityContents: '',
        managerUserId: '',
        sopName: '',
        processName: '',
        regUserId: '',
        chgUserId: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        activityModels: [],
        ctoChecklistModels: [],
        ctoImproveModels: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'ctoInfo',
    };
  },
  computed: {
    tabItems() {
      let items = [{ name: 'ctoInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./ctoInfo.vue'}`), key: uid() },]
      if (this.isOld) {
        items.push({ name: 'ctoChecklist', icon: 'how_to_reg', label: '체크리스트', component: () => import(`${'./ctoChecklist.vue'}`), key: uid() })
        // { name: 'ctoCause', icon: 'camera_enhance', label: '총평 / 근본원인', component: () => import(`${'./ctoCause.vue'}`), disabled: (this.popupParam.sopCtoId ? false : true) },
      }
      return items;
    },
    tabHeight() {
      return String(this.contentHeight - 70);
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    getDetail() {
      this.$emit('getDetail')
    },
  }
};
</script>
